<template>
	<div class="bg_dark bg_intro_1">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character1">
							<div class="text_guide pt-20">
								<h4>{{  $language.auth.access_permission_info }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>{{  $language.auth.access_app_permission_require }}</p>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button class="btn_l btn_fill_gray" @click="$emit('goBack')">{{  $language.common.cancel }}</button>
							<button class="btn_l btn_fill_blue" @click="checkConfirm">{{  $language.common.ok }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia001'
	,data: function(){
		return {
			program: {
				name: this.$language.auth.access_app_info
				, not_header: true
				, not_footer: true
			}
		}
	}
	,methods: {
		checkConfirm: async function(){
			this.$common.inAppWebviewCommunity('android', 'checkPermission')
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>